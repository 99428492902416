<template>
  <modal-edit
    type="morble"
    :model="model"
    :is-edit="isEdit"
    :on-save="onSave"
    :validation="$v"
    @close="$emit('close')"
    @cancel="cancel"
    :title="isEdit ? 'Edit morble' : 'Create custom morble'"
  >
    <div class="Form ColumnWrapper">
      <div class="Column">
        <div class="Group">
          <label class="Label">User</label>
          <model-search
            :model="model.user"
            :api="UserApi"
            @change="model.user = $event.value"
            placeholder="Search for a user"
          />
          <div class="InputHint" v-if="$v.model.user.$error">
            <p v-if="!$v.model.user.required">User is required</p>
          </div>
        </div>
        <div class="Group" v-if="isEdit">
          <label class="Label">Gallery</label>
          <select-box
            :model="model.gallery"
            :options="galleries"
            label-by="name"
            track-by="id"
            :as-object="true"
            @change="model.gallery = $event.value"
          />
        </div>
        <div class="Group">
          <label class="Label">Name</label>
          <input
            class="Input"
            type="text"
            v-model="model.name"
          >
          <div class="InputHint" v-if="$v.model.name.$error">
            <p v-if="!$v.model.name.required">Name is required</p>
          </div>
        </div>
        <div class="Group">
          <label class="Label">Tags</label>
          <tags-input
            :model="model.tags"
            @change="model.tags = $event.value"
          />
        </div>
        <div class="Group">
          <div v-if="!isEdit">
          <check-box
            :model="model.createGallery"
            @change="model.createGallery = $event.value">
            Create linked gallery
          </check-box>
        </div>
          <check-box
            :model="model.isPublic"
            @change="model.isPublic = $event.value"
          >Morble is public (visible by anyone, editable by owner)</check-box>
        </div>
        <div class="Group">
          <label class="Label">Stage</label>
          <div :class="`Pill Pill--large Pill--${model.stage}`">
            {{model.stage}}
          </div>

          <div>
            <button
              v-if="isEdit && model.stage === 'new' && model.image"
              class="Button"
              @click="markReadyToManufacture"
            >
              Mark ready to manufacture
            </button>
          </div>
        </div>
      </div>

      <div class="Column Column--right">
        <div class="Group">
          <label class="Label">Cover image</label>
          <div class="MorbleCoverContainer">
            <div
              class="MorbleCoverImage"
              :style="`background-image: url(${model.imageThumbUrl})`"
            />
          </div>
          <button
            :disabled="['manufacturing', 'manufactured'].includes(model.stage)"
            class="Button"
            @click="editImageHandler">
            {{ model.image ? 'Replace' : 'Upload' }}
          </button>
        </div>
      </div>
    </div>
  </modal-edit>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ModalEdit from '@/components/shared/modal-edit';
import ModelSearch from '@/components/shared/model-search';
import types from '@/constants/morble-types';
import Morble from '@/models/morble.model';
import UserApi from '@/api/user.api';

export default {
  components: { ModalEdit, ModelSearch },

  data() {
    return {
      model: {
        name: null,
        user: null,
        tags: null,
        gallery: null,
        isPublic: null,
        imageThumbUrl: null,
        image: null,
        type: null,
        createGallery: true,
        stage: null,
        lifecycle: {
          readyToManufacture: null,
        },
      },
      types,
      UserApi,
    };
  },

  validations: {
    model: {
      name: { required },
      user: { required },
    },
  },

  props: {
    morble: { type: Object },
    users: { type: Array },
    galleries: { type: Array },
    onSave: { type: Function },
    onRemove: { type: Function },
    isEdit: { type: Boolean },
    editImage: { type: Function },
  },

  emits: ['close'],

  created() {
    //Create model subset
    this.model = this.morble.extract(Object.keys(this.model));
  },

  methods: {
    editImageHandler() {
      this.editImage(this.morble, updatedMorble => {
        const morble = new Morble(updatedMorble);
        this.model.imageThumbUrl = morble.imageThumbUrl;
        this.model.image = morble.image;
      });
    },

    markReadyToManufacture() {
      this.model.lifecycle.readyToManufacture = new Date();
      this.model.stage = 'readyToManufacture';
    },

    cancel() {
      // If we've created a draft new morble with image attached, delete it
      if (!this.isEdit && this.model.image) {
        this.onRemove(false);
      }

      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.ColumnWrapper {
  display: flex;
  justify-content: space-between;
}

.Column--right {
  margin-left: $spacingMedium;
  width: 11em;
}

.MorbleCoverContainer {
  width: 11em;
  height: 11em;
  margin-bottom: $spacingMedium;
}

</style>
